import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { colors, device } from './layout/GlobalStyles';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import facebook from '../images/svgs/facebook.svg';
import instagram from '../images/svgs/instagram.svg';

const scrollToBottom = () => {
    window.scrollTo({
        top: 1050,
        behavior: 'smooth',
    });
};

const StyledHero = styled.section`
    .social {
        display: none;
    }
    .blue-mobile {
        background: linear-gradient(321.57deg, #021417 0%, #14c8a7 144.15%);
        position: absolute;
        bottom: 0em;
        width: 100vw;
        height: 40vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: ${colors.white};
        h2 {
            text-transform: uppercase;
            text-align: center;
            color: ${colors.white};
            border-bottom: 2px solid ${colors.white};
            padding-bottom: 0.7em;
            font-weight: 400;
            max-width: 400px;
        }
        ul {
            display: flex;
            li {
                cursor: pointer;
                padding-top: 2.5em;
                font-size: 18px;
                line-height: 28px;
                letter-spacing: 2px;
                list-style: none;
                margin: 0em 1em;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    @media ${device.laptop} {
        .blue-mobile {
            left: -50vw;
            width: 50vw;
            height: 100vh;
            background: ${colors.gradient};
        }
        .social {
            display: block;
            position: absolute;
            bottom: 3em;
            left: 3.5em;
            img {
                margin: 4em 0em;
            }
        }
    }
`;

const StyledBackgroundImage = styled(BackgroundImage)`
    background-size: cover;
    height: 100vh;
    @media ${device.laptop} {
        background-size: contain;
        width: 50vw;
        margin-left: 50vw;
    }
`;

interface Props {
    heroImage: string;
    title: string;
    services?: boolean;
}

export const Hero: React.FC<Props> = ({ title, heroImage, services }) => {
    const image = getImage(heroImage);
    const bgImage = convertToBgImage(image);

    return (
        <main>
            <StyledBackgroundImage Tag="section" {...bgImage} preserveStackingContext>
                <StyledHero>
                    <div className="blue-mobile">
                        <h2>{title}</h2>
                        <ul>
                            <Link style={{ color: `${colors.white}` }} to="/">
                                <li>HOME</li>
                            </Link>
                            {services && (
                                <Link style={{ color: `${colors.white}` }} to="/surgical">
                                    <li>SERVICES</li>
                                </Link>
                            )}
                            <li onClick={()=>scrollToBottom()}>{title}</li>
                        </ul>
                        <div className="social">
                            <a href="https://www.facebook.com/vytalcenter/" target="__blank">
                                <img className="facebook" src={facebook} alt="facebook logo" />
                            </a>
                            <a href="https://www.instagram.com/vytalcenter/?hl=en" target="__blank">
                                <img className="instagram" src={instagram} alt="instagram logo" />
                            </a>
                        </div>
                    </div>
                </StyledHero>
            </StyledBackgroundImage>
        </main>
    );
};
